@import "src/styles";

.line {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
  height: 5px;
  flex-grow: 0;
  background-color: $progressBarBgColor;
  margin: 0 -2px;
  transition: all ease $pageTransitionDuration;
  transition-delay: $pageTransitionDelay;

  &_feeling {
    background-color: $progressBarActiveColor;
    transition: width 200ms;
    height: 5px;
    border-radius: 2.5px;
    display: block;
    max-width: 100%;
  }
}

.rounded {
  border-radius: 2.5px;
}