@import 'src/styles';

.noteContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 15px;
  margin-top: auto;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  overflow: hidden;
  transition: .3s ease-in-out;

  &.visible {
    pointer-events: all;
    visibility: visible;
    overflow: initial;
    opacity: 1;
  }

  .note {
    position: relative;
    width: 100%;
    max-width: $maxButtonWidth;
    padding: 15px 10px 15px 40px;
    border-radius: 8px;
    background-color: #E1EEEF;

    @include map-styles($questionNoteBlockStyles);

    .title {
      margin-bottom: 10px;
      color: $primaryText;
      font-size: $fontSize18;
      text-align: left;
    }

    .text {
      font-size: $fontSize15;
      font-weight: $fwn;
      text-align: left;
    }

    .icon {
      position: absolute;
      top: 15px;
      left: 10px;
      display: flex;
      justify-content: center;
      align-items: end;
      min-width: 24px;
      min-height: 24px;
      font-size: $fontSize18;
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 40px;
  }
}