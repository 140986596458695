@import "src/styles";

.text {
  color: $preloaderTextColor;
  text-align: center;
  font-size: $fontSize14;
  font-weight: $fwn;
  line-height: $fontSize24;
  position: relative;
  padding: 0 15px;
}
